/* @font-face {
  font-family: '"Open Sans", sans-serif, Arial';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Aeonik-Regular.eot');
  src: local('Aeonik'), url('../../fonts/Aeonik-Regular.woff') format('woff'),
    url('../../fonts/Aeonik-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Aeonik-Regular.ttf') format('truetype');
}

@font-face {
  font-family: '"Open Sans", sans-serif, Arial';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/Aeonik-Medium.eot');
  src: local('Aeonik'), url('../../fonts/Aeonik-Medium.woff') format('woff'),
    url('../../fonts/Aeonik-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Aeonik-Medium.ttf') format('truetype');
}
@font-face {
  font-family: '"Open Sans", sans-serif, Arial';
  font-style: bold;
  font-weight: 700;
  src: url('../../fonts/Aeonik-Bold.eot');
  src: local('Aeonik'), url('../../fonts/Aeonik-Bold.woff') format('woff'),
    url('../../fonts/Aeonik-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Aeonik-Bold.ttf') format('truetype');
*/

.hubspotFormCss, .hubspotFormCssTL {
  margin-top: 1px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
}

.hubspotFormCss form, .hubspotFormCssTL form {
  text-align: center;
  align-self: center;
}

.hubspotFormCss > .submitted-message {
  text-align: center;
  color: white;
  font-size: 2.5rem;
  background-color: #D00000;
  border-radius: 3px;
  border: none;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  font-weight: 700;
  line-height: 2.4rem;
  height: 48px;
}

.hubspotButton {
  text-align: center;
  color: white;
  font-size: 3.5rem;
  background-color: #D00000;
  border-radius: 3px;
  border: none;
  font-weight: 700;
  line-height: 2.4rem;
  height: 48px;
  width: 95%;
}

.hubspotFormCssTL > .submitted-message {
  text-align: center;
  color: black;
  font-size: 2.5rem;
  background-color: #FFD531;
  border-radius: 3px;
  border: none;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  font-weight: 700;
  line-height: 2.4rem;
  height: 48px;
}

.hubspotButtonTL {
  text-align: center;
  color: black;
  font-size: 3.5rem;
  background-color: #FFD531;
  border-radius: 3px;
  border: none;
  font-weight: 700;
  line-height: 2.4rem;
  height: 48px;
  width: 95%;
}